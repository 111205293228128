import React from "react"

import LocaleLink from "../LocaleLink"
import BgImage from "../Image/BgImage"

import { getPrettyURL } from "../../utils"
import { AuthorModel } from "../../models/pages"
import RichText from "../RichText"
import useDictionary from "../../hooks/useDictionary"

interface HeroProps {
  heading?: string
  author?: AuthorModel
  tagList?: React.ReactNode
  pageContext: any
}

const defaultProps = {}

const Hero: React.FC<HeroProps> = props => {
  if (!props.heading) return null

  return (
    <>
      <h1>{props.heading}</h1>

      {(props.author || props.tagList) && (
        <div className="grid-x grid-margin-x align-middle hero__details">
          {props.author && (
            <div className="cell shrink medium-order-2">
              <BgImage
                image={props.author?.mini_img}
                cssClass="hero__authorImage"
              />
            </div>
          )}
          <div className="cell auto">
            {props.author && (
              <div className="hero__author">
                {useDictionary("Written by", props.pageContext.locale)}{" "}
                <LocaleLink
                  to={getPrettyURL(props.author)}
                  pageContext={props.pageContext}
                >
                  <RichText
                    content={props.author.hero.heading}
                    pageContext={props.pageContext}
                  />
                </LocaleLink>
              </div>
            )}
            {props.tagList && <>{props.tagList}</>}
          </div>
        </div>
      )}
    </>
  )
}

Hero.defaultProps = defaultProps

export default Hero
