import React from "react"

import BgImage from "./BgImage"
import Image from "./Image"

import { CmsImage } from "../../models/common"

interface HeroImageProps {
  image?: CmsImage
  focus?: string
  defaultImage?: CmsImage
  cssClass?: string
}

const defaultProps = {}

export const HeroImage: React.FC<HeroImageProps> = props => {
  if (!props.image && !props.defaultImage) return null

  const image = props.image || props.defaultImage
  const focus = props.image ? props.focus : undefined

  return (
    <BgImage
      image={image}
      backgroundPosition={focus}
      cssClass={`heroImage__wrapper ${props.cssClass || ""}`}
    >
      <Image image={image} cssClass="heroImage__image" />
    </BgImage>
  )
}

HeroImage.defaultProps = defaultProps

export default HeroImage
