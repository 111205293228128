import React, { useState, useRef, useLayoutEffect } from "react"

import DownArrow from "../images/svg/icon_caretDown.svg"

import { useCurrentWidth } from "../hooks/useCurrentWidth"

interface AccordionProps {
  heading: string
  cssClass?: string
  arrowColor?: string
  children?: React.ReactElement | string
}

const defaultProps = {}

export const Accordion: React.FC<AccordionProps> = props => {
  // state
  const [active, setActive] = useState(false)
  const [fullHeight, setFullHeight] = useState(0)

  // refs
  const contentRef = useRef<HTMLDivElement>(null)

  // hooks
  const width = useCurrentWidth()

  // effects
  useLayoutEffect(() => {
    setTimeout(() => {
      if (contentRef?.current)
        setFullHeight(contentRef.current.getBoundingClientRect().height)
    }, 100)
  }, [contentRef, width])

  if (!props.children) return null

  // events
  const toggleActive = () => {
    setActive(!active)
  }

  return (
    <div
      className={`accordion ${active ? "active" : ""} ${props.cssClass || ""}`}
    >
      <button className="accordion__heading h3" onClick={toggleActive}>
        {props.heading}
        <DownArrow
          fill={props.arrowColor || "#404142"}
          className="accordion__trigger"
        />
      </button>
      <div
        className="accordion__content"
        style={active ? { height: fullHeight } : { height: 0 }}
      >
        <div className="accordion__contentInner" ref={contentRef}>
          {props.children}
        </div>
      </div>
    </div>
  )
}

Accordion.defaultProps = defaultProps

export default Accordion
