import React from "react"

import LocaleLink from "./LocaleLink"

import { buildBreadcrumbs } from "../utils/utils_links"
import { PageModels } from "../models/pages"
import useDictionary from "../hooks/useDictionary"

interface BreadcrumbsProps {
  breadcrumbs?: PageModels[]
  currentPage?: string
  pageContext: any
}

const defaultProps = {}

export const Breadcrumbs: React.FC<BreadcrumbsProps> = props => {
  if (!props.breadcrumbs && !props.currentPage) return null

  const breadcrumbs = buildBreadcrumbs(props.breadcrumbs).filter(x => x.label)

  return (
    <section className="breadcrumbs">
      <div className="grid-container">
        <div className="grid-x grid-margin-x">
          <div className="cell">
            <ul className="breadcrumbs__list">
              <li className="breadcrumb" role="presentation">
                <LocaleLink
                  to={"/"}
                  className="navLink"
                  role="menuitem"
                  pageContext={props.pageContext}
                >
                  {useDictionary("Home", props.pageContext.locale)}
                </LocaleLink>
              </li>

              {breadcrumbs?.map(item => (
                <li key={item.id} className="breadcrumb" role="presentation">
                  <LocaleLink
                    to={item.url}
                    className="navLink"
                    role="menuitem"
                    pageContext={props.pageContext}
                  >
                    {item.label}
                  </LocaleLink>
                </li>
              ))}

              {props.currentPage && (
                <li className="breadcrumb">{props.currentPage}</li>
              )}
            </ul>
          </div>
        </div>
      </div>
    </section>
  )
}

Breadcrumbs.defaultProps = defaultProps

export default Breadcrumbs
