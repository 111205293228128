import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import get from "lodash/get"

import Seo from "../components/Seo"
import Breadcrumbs from "../components/Breadcrumbs"
import HeroImage from "../components/Image/HeroImage"
import Hero from "../components/Hero/Hero"
import PageLayout from "../components/PageLayout/PageLayout"
import BlockList from "../components/PageLayout/BlockList"
import TagList from "../components/TagList/TagList"
import RichText from "../components/RichText"
import Accordion from "../components/Accordion"
import ArticleCard from "../components/CardTypes/ArticleCard"

import {
  getPlainText,
  getPrettyURL,
  isEmptyArray,
  isEmptyRichText,
} from "../utils"
import {
  HEALTH_NUTRITION_PAGE,
  SOCIAL_PURPOSE_PAGE,
  ABOUT_PAGE,
} from "../utils/utils_constants"
import { ArticleModel } from "../models/pages"
import { SeoDataModel } from "../models/common"
import { Config } from "../models/config"
import useDictionary from "../hooks/useDictionary"
import { fetchLivePreviewData, contentstackLivePreviewWrapper} from "../live-preview"

const defaultProps = {}

export const ArticlePage: React.FC<ArticleModel> = props => {

  // page data
  const _page = get(props, "data.page") as any as ArticleModel;
  _page.pageContext = props.pageContext;

  const _subscribe = get(props, "data.subscribe") as any as Config

  const [page, setPage] = useState(_page);
  const [subscribe, setSubscribe] = useState(_subscribe);
  
  useEffect(() => {
    contentstackLivePreviewWrapper(fetchLivePreviewData(_page, setPage, props.pageContext));
    contentstackLivePreviewWrapper(fetchLivePreviewData(_subscribe, setSubscribe));
  }, [])
 
  // page heading
  const heroHeading = getPlainText(page.hero.heading)
  // const navLabel = getPlainText(page.nav_label)

  // SEO Fields
  const seoData: SeoDataModel = {
    heading: heroHeading,
    metaTitle: page.seo?.title || heroHeading,
    metaDescription:
      page.seo?.description || getPlainText(page.listing?.excerpt),
    metaLink: page.seo?.canonical_link,
    metaKeywords: page.seo?.keywords,
    metaImage: page.seo?.share_image,
    slug: `/${props.pageContext.language}/${getPrettyURL(page)}`,
    locale: props.pageContext.locale,
    amp: true,
    pageContext: props.pageContext,
  }

  // do any of the body groups have a sidebar?
  // used so that all layout groups will align
  const pageHasSidebar = !!page.body
    ?.map(x => x.sidebar.length > 0)
    ?.filter(Boolean)?.length

  // root page for tag list links
  const rootPage =
    page.article_type === "Health & Nutrition"
      ? HEALTH_NUTRITION_PAGE
      : props.article_type === "Social Purpose"
      ? SOCIAL_PURPOSE_PAGE
      : ABOUT_PAGE

  // build hero component
  const hero = (
    <Hero
      heading={heroHeading}
      author={page.author[0]}
      tagList={
        page.categories.length ? (
          <TagList
            tags={page.categories}
            link={rootPage}
            pageContext={props.pageContext}
          />
        ) : undefined
      }
      pageContext={props.pageContext}
    />
  )

  return (
    <div className="articlePage">
      <Seo {...seoData} />

      {/* Breadcrumbs */}
      {!page.hero.hideBreadcrumbs && (
        <Breadcrumbs
          breadcrumbs={page.url_structure}
          pageContext={props.pageContext}
        />
      )}

      {/* Hero Image */}
      {page.hero.hero_image && (
        <section className="hero">
          <div className="grid-container">
            <HeroImage
              image={page.hero.hero_image}
              focus={page.hero.image_focus}
            />
          </div>
        </section>
      )}

      {/* Page Content */}
      {page.body?.map((layout, index) => (
        <PageLayout
          key={`layout_${index}`}
          {...layout}
          hasSidebar={pageHasSidebar}
          hero={index === 0 ? hero : undefined}
          pageContext={props.pageContext}
        />
      ))}

      {/* References */}
      {!isEmptyRichText(page.references) && (
        <section
          className={`page__references ${pageHasSidebar ? "with-sidebar" : ""}`}
          style={{ marginTop: 60, marginBottom: 40 }}
        >
          <div className="grid-container">
            <div className="grid-x grid-margin-x">
              <div className="cell">
                <div className="page__body">
                  <Accordion
                    heading={useDictionary(
                      "References",
                      props.pageContext.locale
                    )}
                    cssClass="left"
                  >
                    <RichText
                      content={page.references}
                      cssClass="content"
                      pageContext={props.pageContext}
                    />
                  </Accordion>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}

      {/* Page Categories */}
      {!page.hide_categories && !isEmptyArray(page.categories) && (
        <section
          className={`moreIn ${pageHasSidebar ? "with-sidebar" : ""}`}
          style={{ marginTop: 60, marginBottom: 40 }}
        >
          <div className="grid-container">
            <div className="grid-x grid-margin-x">
              <div className="cell">
                <div className="page__body content">
                  {useDictionary("More in", props.pageContext.locale)} &nbsp;
                  <TagList
                    tags={page.categories}
                    link={rootPage}
                    pageContext={props.pageContext}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      )}

      {/* Page Blocks */}
      {subscribe && (
        <BlockList
          items={subscribe?.blocks || []}
          pageContext={props.pageContext}
        />
      )}

      {/* Related Content */}
      {!!page.related_content?.length && (
        <section
          className={`${pageHasSidebar ? "with-sidebar" : ""} hide-for-print`}
        >
          <div className="grid-container">
            <div className="grid-x grid-margin-x">
              <div className="cell">
                <h2
                  className="text-center"
                  style={{ fontSize: 40, color: "#ef2c32", marginBottom: 30 }}
                >
                  {useDictionary("You might also", props.pageContext.locale)}{" "}
                  <span style={{ whiteSpace: "nowrap" }}>{useDictionary("like", props.pageContext.locale)} ...</span>
                </h2>
                <div
                  className="grid-x flexGrid"
                  style={{ maxWidth: 1200, margin: "0 auto" }}
                >
                  {page.related_content.map(item => (
                    <div key={item.id} className="cell medium-6 grid__item--1">
                      {item.internal.type === "Contentstack_article" && (
                        <ArticleCard
                          {...(item as ArticleModel)}
                          pageContext={props.pageContext}
                        />
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </div>
  )
}

ArticlePage.defaultProps = defaultProps

export default ArticlePage

/******************************************************
	Data
******************************************************/
export const query = graphql`
  query ArticlePageByID($pageId: String!, $locale: String) {
    page: contentstackArticle(id: { eq: $pageId }) {
      __typename
      uid
      ...ArticleFields
      hero {
        hide_breadcrumbs
        hero_image {
          ...HeroImageFields
        }
      }
      author {
        ...AuthorFields
      }
      hide_categories
      hide_share
      unrelated
      exclude_from_amp
      body {
        body {
          rich_text {
            rich_text
          }
          html_text {
            html
          }
          columns {
            separator
            column_1_promo_links
            col_1_text
            column_1_promo_links
            col_1_components {
              ... on Contentstack_button {
                ...ButtonFields
              }
              ... on Contentstack_copy {
                ...CopyFields
              }
              ... on Contentstack_media {
                ...MediaFields
              }
              ... on Contentstack_video {
                ...VideoFields
              }
            }
            col_2_text
            column_2_promo_links
            col_2_components {
              ... on Contentstack_button {
                ...ButtonFields
              }
              ... on Contentstack_copy {
                ...CopyFields
              }
              ... on Contentstack_media {
                ...MediaFields
              }
              ... on Contentstack_video {
                ...VideoFields
              }
            }
          }
          banner {
            banner {
              ...BannerFields
            }
          }
          button {
            label
            anchor {
              ...AnchorFields
            }
            button {
              ...ButtonFields
            }
          }
          copy {
            copy {
              ...CopyFields
            }
          }
          form {
            forms {
              tab_heading
              form_type
              content_before
              content_after
            }
          }
          image {
            image {
              ...CmsImageFields
              gatsbyImageData(quality: 95)
            }
            alt_text
            caption
            anchor {
              ...AnchorFields
            }
          }
          image_slider {
            heading
            center_title
            slides {
              slide_name
              show_name
              image {
                ...CmsImageFields
                gatsbyImageData(quality: 95)
              }
              url
              entry {
                ... on Node {
                  ...AllPageLinks
                }
              }
              asset {
                ...CmsAssetFields
              }
              new_window
            }
          }
          video {
            video_url
            caption
          }
          recipe_collection {
            center_title_text
            number_of_records
            title
            collection {
              hero {
                heading
              }
              recipes {
                ...RecipeFields
              }
            }
          }
        }
        sidebar {
          rich_text {
            rich_text
          }
          banner {
            banner {
              ...BannerFields
            }
          }
          button {
            label
            anchor {
              ...AnchorFields
            }
            button {
              ...ButtonFields
            }
          }
          copy {
            copy {
              ...CopyFields
            }
          }
          image {
            image {
              ...CmsImageFields
              gatsbyImageData(quality: 95)
            }
            alt_text
            caption
            anchor {
              ...AnchorFields
            }
          }
          menu {
            navigation_group {
              ...NavGroupFields
            }
          }
        }
        blocks {
          ...AllBlocks
        }
      }
      references
      related_content {
        ... on Contentstack_article {
          ...ArticleFields
        }
      }
      seo {
        title
        description
        canonical_link
        keywords
        share_image {
          ...CmsImageFields
          gatsbyImageData(quality: 95)
        }
      }
    }

    subscribe: contentstackConfig(
      code_id: { eq: "subscribeBanner" }
      publish_details: { locale: { eq: $locale } }
    ) {
      ...ConfigFields
    }
  }

  fragment ArticleFields on Contentstack_article {
    id
    url
    url_structure {
      ...UrlStructureFields
    }
    hero {
      heading
      image_focus
      listing_img: hero_image {
        ...CmsImageFields
        #gatsbyImageData(width: 560,  quality: 95)
        gatsbyImageData(height: 290, quality: 95)
      }
      mini_img: hero_image {
        ...CmsImageFields
        gatsbyImageData(height: 75, quality: 95)
      }
    }
    article_type
    publish_date
    categories {
      ...TagFields
    }
    listing {
      excerpt
      listing_img: listing_image {
        ...CmsImageFields
        #gatsbyImageData(width: 560,  quality: 95)
        gatsbyImageData(height: 290, quality: 95)
      }
      mini_img: listing_image {
        ...CmsImageFields
        gatsbyImageData(height: 75, quality: 95)
      }
      darken_image
      listing_color
      listing_width
      promo_size
    }
    enable_sites {
      sites
    }
    internal {
      type
    }
    publish_details {
      locale
    }
  }
`
